import React, { useState, useCallback, useEffect } from 'react'
import { Medicine } from './components'
import { Room } from '../'
import { Models } from '../../types'
import { MedicineGame } from 'src/components'
import { lastActionSelector } from 'src/redux/ducks/app'
import { useSelector } from 'react-redux'

export const Bathroom: React.FC = () => {
  const lastAction = useSelector((state) => lastActionSelector(state))

  const [isMedicineVisible, setIsMedicineVisible] = useState(false)
  const toggleMedicineModal = useCallback(() => {
    setIsMedicineVisible(!isMedicineVisible)
  }, [isMedicineVisible, setIsMedicineVisible])

  const objectClickHandler = useCallback(
    (mesh) => {
      console.log('mesh', mesh.name)

      switch (mesh.name) {
        case 'Medicine':
        case 'Plane004':
        case 'Circle004':
        case 'Circle011': {
          toggleMedicineModal()
          return
        }
      }
    },
    [isMedicineVisible, setIsMedicineVisible, toggleMedicineModal],
  )

  const [isGameShow, setIsGameShow] = useState(false)
  const toggleGame = useCallback(() => {
    setIsGameShow(!isGameShow)
  }, [isGameShow, setIsGameShow])

  const [isMedicineEndModalVisible, setIsMedicineEndModalVisible] = useState(
    false,
  )
  const toggleMedicineEndModal = useCallback(() => {
    setIsMedicineEndModalVisible(!isMedicineEndModalVisible)
  }, [isMedicineEndModalVisible, setIsMedicineEndModalVisible])

  useEffect(() => {
    if (lastAction) {
      setIsMedicineVisible(true)
      setIsMedicineEndModalVisible(true)
    }
  }, [])

  return (
    <>
      <Room model={Models.bathroom} handleObjectClick={objectClickHandler} />

      {isMedicineVisible && (
        <Medicine
          isMedicineEndModalVisible={isMedicineEndModalVisible}
          toggleMedicineEndModal={toggleMedicineEndModal}
          openGame={toggleGame}
          onClose={toggleMedicineModal}
        />
      )}

      {isGameShow && <MedicineGame closeGame={toggleGame} />}
    </>
  )
}
