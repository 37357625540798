import React, { useState, useCallback } from 'react'
import { Room } from '../'
import { Models } from '../../types'
import { TVModal } from './components'
import { TVGame } from 'src/components/Games'

export const LivingRoom: React.FC = () => {
  const [isTVModalVisible, setIsTVModalVisible] = useState(false)
  const toggleTVModal = useCallback(() => {
    setIsTVModalVisible(!isTVModalVisible)
  }, [isTVModalVisible, setIsTVModalVisible])

  const [isGameShow, setIsGameShow] = useState(false)
  const toggleGame = useCallback(() => {
    setIsGameShow(!isGameShow)
  }, [isGameShow, setIsGameShow])

  const objectClickHandler = (mesh) => {
    console.log('mesh', mesh.name)

    switch (mesh.name) {
      case 'TV': {
        toggleTVModal()
        return
      }
    }
  }

  return (
    <>
      <div className="progress-bar">
        <div className="progress"></div>
      </div>
      <Room model={Models.LivingRoom} handleObjectClick={objectClickHandler} />

      <TVModal
        visible={isTVModalVisible}
        onClose={toggleTVModal}
        openGame={toggleGame}
      />

      {isGameShow && <TVGame closeGame={toggleGame} />}
    </>
  )
}
