import React from 'react'
import { Title, Text, Card, Modal } from 'src/components'
import { ModalProps } from 'antd'
import { useDispatch } from 'react-redux'
import cloudTV from './cloud-TV.svg'
import onlineCinema from './online-cinema.svg'
import sberBox from './sber-box.svg'

import { Modals } from 'src/types'
import { appActions } from 'src/redux/ducks/app'

import styled from 'styled-components'

import history from 'src/history'

const Styled = {
  ImageWrapper: styled.div`
    @media (max-height: 374px) {
      height: 130px;
    }
  `,
}

type TVModalProps = ModalProps & {
  onClose: VoidFunction
  openGame: VoidFunction
  visible: boolean
}

export const TVModal: React.FC<TVModalProps> = (props) => {
  const { onClose, openGame } = props
  const dispatch = useDispatch()

  const handleTVClick = () => {
    onClose()
    dispatch(appActions.appModalOpen(Modals.minusChannel))
  }

  const handleOnlineCinemaClick = () => {
    onClose()
    openGame()
  }

  const handleSberDevicesClick = () => {
    history.push('/city/home/bedroom/sber-portal')
  }

  return (
    <Modal {...props} centered={true} footer={null}>
      <Title type="h1">Посмотреть кино - отличное решение.</Title>
      <Text type="body2">Что выбираете?</Text>
      <Card.List>
        <Card.Item onClick={handleOnlineCinemaClick}>
          <Styled.ImageWrapper>
            <Card.ItemImage>
              <img src={onlineCinema} alt="" />
            </Card.ItemImage>
          </Styled.ImageWrapper>
          <Text type="body1">Онлайн-кинотеатр</Text>
        </Card.Item>
        <Card.Item onClick={handleTVClick}>
          <Styled.ImageWrapper>
            <Card.ItemImage>
              <img src={cloudTV} alt="" />
            </Card.ItemImage>
          </Styled.ImageWrapper>
          <Text type="body1">Обычный телеканал</Text>
        </Card.Item>
        <Card.Item onClick={handleSberDevicesClick}>
          <Styled.ImageWrapper>
            <Card.ItemImage>
              <img src={sberBox} alt="" />
            </Card.ItemImage>
          </Styled.ImageWrapper>
          <Text type="body1">Узнать про СБЕР устройства</Text>
        </Card.Item>
      </Card.List>
    </Modal>
  )
}
