import React from 'react'
import { useDispatch } from 'react-redux'
import { gamesActions } from 'src/redux/ducks/games'
import { mapActions, Scenario } from 'src/redux/ducks/map'
import { Title, Text, Card } from 'src/components'
import { ModalProps } from 'antd'
import car from 'src/shared/img/car.svg'
import bus from 'src/shared/img/bus.svg'
import taxi from 'src/shared/img/taxi-in-profile.svg'
import { appActions } from 'src/redux/ducks/app'
import { GameActions } from 'src/redux/ducks/app'

type VisitProps = ModalProps & {
  onClose: VoidFunction
}

export const Visit: React.FC<VisitProps> = (props) => {
  const dispatch = useDispatch()
  const { onClose } = props

  const onTaxiClick = () => {
    dispatch(gamesActions.showTaxiGame())
    dispatch(appActions.setLastAction(GameActions.taxiGame))
    onClose()
  }

  const goByCar = () => {
    onClose()
    dispatch(mapActions.activateScenario(Scenario.goHomeByCar))
    dispatch(appActions.setLastAction(Scenario.goHomeByCar))
  }

  const goByBus = () => {
    onClose()
    dispatch(mapActions.activateScenario(Scenario.goHomeByBus))
    dispatch(appActions.setLastAction(Scenario.goHomeByBus))
  }

  return (
    <>
      <Title type="h1">Вы решили поехать в гости</Title>
      <Text type="body2">Как будете добираться?</Text>
      <Card.List>
        <Card.Item onClick={goByCar}>
          <Card.ItemImage>
            <img src={car} alt="" />
          </Card.ItemImage>
          <Text type="body1">На личном авто</Text>
        </Card.Item>
        <Card.Item onClick={goByBus}>
          <Card.ItemImage>
            <img src={bus} alt="" />
          </Card.ItemImage>
          <Text type="body1">На автобусе</Text>
        </Card.Item>
        <Card.Item onClick={onTaxiClick}>
          <Card.ItemImage>
            <img src={taxi} alt="" />
          </Card.ItemImage>
          <Text type="body1">На такси</Text>
        </Card.Item>
      </Card.List>
    </>
  )
}
