import React, { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Benefits } from './components'
import { Styled } from './styles'
import home from 'src/shared/img/home.svg'
import city from './city.svg'
import { useHistory } from 'react-router-dom'
import { appActions } from 'src/redux/ducks/app'
import { mapActions } from 'src/redux/ducks/map'
import { Modals } from 'src/types/modals'

export const Controls: React.FC<{}> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = history.location

  const isCityRoute = location.pathname.endsWith('/city')

  const openPlan = () => {
    dispatch(appActions.setLastAction(null))
    dispatch(mapActions.removeScenario())
    history.push('/city/plan')
  }

  const showBenefits = () => {
    dispatch(appActions.appModalOpen(Modals.prime))
  }

  return (
    <div>
      <Styled.Controls>
        <Styled.Item onClick={showBenefits}>
          <Benefits />
        </Styled.Item>
        {isCityRoute ? (
          <Styled.Item onClick={openPlan}>
            <img src={home} alt="" />
          </Styled.Item>
        ) : (
          <Styled.Item onClick={() => history.push('/city')}>
            <img src={city} alt="" />
          </Styled.Item>
        )}
      </Styled.Controls>
    </div>
  )
}
